<template>
    <div class="help">
        <!-- <p class="pageTop">
          <Language />
      </p> -->
      
        <div class="help_title">
            <p v-if="!mobileFlag">{{ $t('title_helpTxt') }}</p>
            <el-input  :placeholder="$t('helpSearch')" v-model="searchInput" class="help_searchInput">
                
                <i slot="prepend" class="el-icon-search"></i>
                <el-button slot="append" @click="getSearch()">{{ $t('MyFollowers_Search') }}</el-button>
            </el-input>
        </div>
        <div class="help_content">
            <div class="" v-show="searchList.length !== 0">
                <div  class="help_search"  v-infinite-scroll="load"
                        :infinite-scroll-disabled="disabled_x"
                        :infinite-scroll-distance="10"
                        style="overflow:auto;padding: 5px;"
                >
                    <div  class="search_item"   v-for="(item,index) in searchList"  :key="index" style="overflow:auto">
                        <p class="search_title" v-html="item.title">
                            
                        </p>
                        <p class="search_content" v-html="item.content">
                            
                        </p>
                    </div>
                    
                </div>
            </div>
           
            <div v-show="searchList.length == 0"  class="help_null">
                <img src="../../assets/images/HelpNull.svg" alt="">
                <div class="help_nullTxt">
                    {{ $t('helpNull') }}
                </div>
            </div>
            
            
            <div class="help_list">
                <p class="list_title">
                    <img src="../../assets/images/NormalQuestion.svg" alt="">
                    {{ $t('helpNormal') }}
                </p>
                <div class="bscroll" ref="bscroll">
          <div class="bscroll-container">
                <el-collapse  accordion @change="collapseChange">
                    <el-collapse-item  v-for="(item,index) in helpList" :title="item.title" :name="index" :key="index">
                        <template slot="title">
                            {{ item.title }}
                            <svg-icon class="helpArrow_icon"  icon-class="HelpArrow"></svg-icon>
                        </template>
                        <p class="search_content" v-html="item.content">
                           
                        </p>
                    </el-collapse-item>
                </el-collapse>
                <div class="x" v-if="up && helpList.length < total">
                      <i  class="el-icon-loading "></i>
                      {{ $t('loading') }}
                    </div>
                    <div class="x" v-if="!up && helpList.length >= total">
                      
                      {{ $t('noMoreData') }}
                    </div>
                <el-pagination class="tablePage" :prev-text="$t('MyFollowers_PreviousPage')"
                :next-text="$t('MyFollowers_NexPage')" @current-change="handleCurrentChange" :current-page="currentPage"
                @size-change="handleSizeChange" :page-sizes="[5, 10, 20, 50, 100]" :pager-count="5" :page-size="pageSize"
                layout="prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { concatParams, concatTableData } from "../../plugins/common.js";
import { mapState } from "vuex";
import CollapseTransition from '@/plugins/utils/collapse-transition'; 
import { debounce } from 'lodash';
import BScroll from '@better-scroll/core'
  import Pullup from '@better-scroll/pull-up'
  
  BScroll.use(Pullup)

export default {
    name: 'Help',
    components: {
        CollapseTransition
    },
    computed: {
        ...mapState(["checkPortfolio", "mobileFlag"]),
    },
    data() {
        return {
            currentPage: 1,
            total: 0,
            pageSize: 5,
            helpList:[],
            searchInput:'',
            searchList:[],
            searchIndex:1,
            searchSize:3,
            searchTotal:0,
            disabled_x: false,
            up:false
        }
    },
    mounted() {
        // window.addEventListener('load', console.log(1111111))
    },
    created() {
        if(this.mobileFlag){
             this.getData(1,10)

        }else{
            this.getData(1,5)

        }
    },
    methods: {
        collapseChange(){
            this.$nextTick(() => {
                if(!this.firstOpen){
                this.scroll.maxScrollY = this.scroll.maxScrollY - 300
                this.firstOpen = true
                }else{
                this.scroll.maxScrollY = this.scroll.maxScrollY + 300
                this.firstOpen = false
                }
            });
        },
        load:debounce (function(){
            this.disabled_x = true
            if(this.searchTotal > this.searchList.length){
                this.searchIndex = this.searchIndex + 1
                var params = {
                    searchContent: this.searchInput,
                    pageIndex: this.searchIndex ,
                    pageSize: this.searchSize
                }
                this.$axios.get('/Api/Question/Questions' + concatParams(params)).then((res) => {
                    let dt = res.data
                    if (dt.isSuccess) {
                        this.searchTotal = dt.result.total
                        this.changeColor(this.searchList.concat(dt.result.pageList))
                    }
                })
            }
            this.disabled_x = false
            
        },300),
        scrollFn(){
            this.$nextTick(() => {
            
                if (!this.scroll) {
                    this.scroll = new BScroll(this.$refs.bscroll, {
                        click: true,
                        scrollY: true,
                        probeType: 3
                    });
                }else{
                    this.scroll.refresh();
                }
                
                //touchEnd（手指离开以后触发） 通过这个方法来监听下拉刷新
                this.scroll.on('touchEnd', (pos) => {
                    //上拉加载 总高度>下拉的高度+10 触发加载更多
                    if(this.scroll.maxScrollY>pos.y + 50){
                        // console.log("加载更多")
                        this.up=true;
                        setTimeout(()=>{
                        //使用refresh 方法 来更新scroll  解决无法滚动的问题
                            if(this.helpList.length < this.total){
                                this.currentPage = this.currentPage + 1
                                this.getData(this.currentPage,this.pageSize,'noRefresh')
                                this.scroll.refresh();
                                this.up=false;
                            }else{
                                this.up=false;
                            }
                            
                    },1000) 
                    
                    }
                    // console.log(this.scroll.maxScrollY+"总距离----下拉的距离"+pos.y)
                })
            
                // console.log(this.scroll.maxScrollY)
            });
        },
        getSearch(){
            // console.log(this.tableSearchForm.length)
            
            var params = {
                searchContent: this.searchInput,
                pageIndex: this.searchIndex ,
                pageSize: this.searchSize
            }
            this.$axios.get('/Api/Question/Questions' + concatParams(params)).then((res) => {
                let dt = res.data
                if (dt.isSuccess) {
                    this.searchTotal = dt.result.total
                    this.changeColor(dt.result.pageList)
                    
                   
                }
            })
        },
        getData(pageIndex, pageSize,tag) {
            if(!this.mobileFlag || tag !== 'noRefresh'){
                this.helpList = [];

            }
            // console.log(this.tableSearchForm.length)
            var params = {
                searchContent: '',
                pageIndex: pageIndex,
                pageSize: pageSize
            }
            this.$axios.get('/Api/Question/Questions' + concatParams(params)).then((res) => {
                let dt = res.data
                if (dt.isSuccess) {
                    this.total = dt.result.total
                    if(this.mobileFlag && tag == 'noRefresh'){
                        dt.result.pageList.forEach((item) =>{
                            this.helpList.push(item)
                        })
                    }else{
                     this.helpList = dt.result.pageList
                    }
                    this.fullscreenLoading = false;
                    if(this.mobileFlag){
                        this.scrollFn()
                    }
                }
            })
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getData(this.currentPage, this.pageSize)
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getData(this.currentPage, this.pageSize);
        },
        changeColor(result) {
        //result为接口返回的数据
            result.map((item, index) => {
                if (this.searchInput) {
                /**
                 * 使用正则表达式进行全文匹配关键词
                 * ig : 表示 全文查找 ,忽略大小写
                 *  i : 忽略大小写
                 *  g : 全文查找
                 *
                 * 使用字符串的replace方法进行替换
                 * stringObject.replace('被替换的值',替换的值)
                     title和name是你要高亮的字段
                */
                    let replaceReg = new RegExp(this.searchInput, "ig");
                    let replaceString = `<span style="color: #4D71FF">${this.searchInput}</span>`;
                    result[index].title = item.title.replace(replaceReg, replaceString);
                    result[index].content = item.content.replace(replaceReg, replaceString);
                }
            });
            this.searchList = result;
        },
    }
}
</script>
<style lang="less" scoped>
.help{
    .help_title{
        padding: 50px 90px;
        background-color: #EEF2F5;
        background-image: url(../../assets/images/HelpBack.svg);
        background-repeat: no-repeat;
        background-position: right;
        p{
            color: #0B205D;
            font-family: 'PingFangSC-Semibold';
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0 0 20px 0;
        }
        .help_searchInput{
            width: 60%;
            max-width: 822px;
            border-radius: 191px;
        }
    }
    .help_content{
        background: #fff;
        padding: 30px 135px 37px 90px;
        .help_search{
            height: 240px;
            overflow: auto;
            
        }
        .help_null{
            min-height: 240px;
            max-height: 240px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .help_nullTxt{
                color: #CCC;
                font-family: 'PingFangSC-Medium';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal; 
                margin-top: 10px;
            }
        }
        .help_list{
            margin-top: 40px;
            .list_title{
                color: #0B205D;
                font-family: 'PingFangSC-Medium';
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                display: flex;
                flex-direction: row;
                align-items: center;
                img{
                    margin-right: 9px;
                }
            }
            .helpArrow_icon{
                margin-left: 9px;
                transform: rotate(0deg);
                transition: transform .3s,-webkit-transform .3s;
                color: #0B205D;
            }
        }
        .search_item{
            border-bottom: 1px solid rgba(11, 32, 93, .2);
            margin-bottom: 30px;
            .search_title{
                color: #0B205D;
                font-family: 'PingFangSC-Medium';
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 0 0 20px 0;
            }
            .search_content{
                color: #0B205D;
                font-family: 'PingFangSC-Regular';
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                margin-bottom: 20px;
            }

        }
    }
    .tablePage{
        margin-top: 40px;
        text-align: center;
    }
}

@media screen and (max-width:767px) {
    .help{
        height: 100%;
        .help_title{
            padding: 20px 16px;
            background: linear-gradient(90deg, #FFF 10.39%, rgba(255, 255, 255, 0.00) 99.67%);
            .help_searchInput{
                width: 100%;
            }
            
        }
        .help_content{
                padding: 0 16px;
                background: transparent;
            .help_list {
                font-size: 16px;
                margin: 20px 0;
            }
            .bscroll{
                height: 300px;
            }
            .bscroll-container{
                    padding-top: 0;
            }
            .search_item{
                .search_title{
                    font-size: 16px;
                }
                .search_content{
                    font-size: 14px;

                }
            } 
        }
    }
}

</style>
